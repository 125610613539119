import { useAuthFakeStore } from "@/state/pinia";
export default [
  {
    path: "/",
    name: "default",
    meta: { title: "Wallboard", authRequired: true },
    component: () => import("../views/dashboards/wallboard")
  },
  {
    path: "/general/wallboard",
    name: "wallboard",
    meta: { title: "Wallboard", authRequired: true },
    component: () => import("../views/dashboards/wallboard")
  },
  {
    path: "/general/wallboard-map-pemda",
    name: "wallboardMapPemda",
    meta: { title: "Wallboard Proges Pemda", authRequired: true },
    component: () => import("../views/dashboards/wallboard-map-pemda")
  },
  {
    path: "/general/wallboard-map-pemda-new-site",
    name: "wallboardMapPemdaNewSite",
    meta: { title: "Wallboard Proges Pemda", authRequired: true },
    component: () => import("../views/dashboards/wallboard-map-pemda-new-site")
  },
  {
    path: "/general/wallboard-adendum-pks",
    name: "wallboardAdendum",
    meta: { title: "Wallboard Adendum PKS", authRequired: true },
    component: () => import("../views/dashboards/wallboard-adendum")
  },
  {
    path: "/general/wallboard-map-hutan",
    name: "wallboardMapHutan",
    meta: { title: "Wallboard Proges Hutan", authRequired: true },
    component: () => import("../views/dashboards/wallboard-map-hutan")
  },
  {
    path: "/general/wallboard-map-hutan-lindung",
    name: "wallboardMapHutanLindung",
    meta: { title: "Wallboard Proges Hutan Lindung", authRequired: true },
    component: () => import("../views/dashboards/wallboard-map-hutan-lindung")
  },
  {
    path: "/general/wallboard-map-hutan-produksi",
    name: "wallboardMapHutanProduksi",
    meta: { title: "Wallboard Proges Hutan Produksi", authRequired: true },
    component: () => import("../views/dashboards/wallboard-map-hutan-produksi")
  },
  {
    path: "/general/wallboard-map-hutan-konservasi",
    name: "wallboardMapHutanKonservasi",
    meta: { title: "Wallboard Proges Hutan Konservasi", authRequired: true },
    component: () => import("../views/dashboards/wallboard-map-hutan-konservasi")
  },
  {
    path: "/general/startWallboard",
    name: "startWallboard",
    meta: { title: "Wallboard", authRequired: true },
    component: () => import("../views/dashboards/wallboard-start")
  },
  {
    path: "/pustakahukum/jenis_peraturan",
    name: "jenisPeraturan",
    meta: { title: "Jenis Peraturan", authRequired: true },
    component: () => import("../views/jenisperaturan/jenisperaturan")
  },
  {
    path: "/pustakahukum/jenis_peraturan/create",
    name: "jenisperaturanCreate",
    meta: { title: "Create Jenis Peraturan", authRequired: true },
    component: () => import("../views/jenisperaturan/create")
  },
  {
    path: "/pustakahukum/jenis_peraturan/update/:id",
    name: "jenisperaturanUpdate",
    meta: { title: "Update Jenis Peraturan", authRequired: true },
    component: () => import("../views/jenisperaturan/update")
  },
  {
    path: "/pustakahukum/kategori_kawasan",
    name: "kategoriKawasan",
    meta: { title: "Jenis Peraturan", authRequired: true },
    component: () => import("../views/kategorikawasan/kategorikawasan")
  },
  {
    path: "/pustakahukum",
    name: "pustakahukum",
    meta: { title: "Pustaka Hukum", authRequired: true },
    component: () => import("../views/pustakahukum/pustakahukum")
  },
  {
    path: "/pustakahukum/detail/:id",
    name: `pustakahukumDetail`,
    meta: { title: "Detail Pustaka Hukum", authRequired: true },
    component: () => import("../views/pustakahukum/detail")
  },
  {
    path: "/pustakahukum/create",
    name: "pustakahukumCreate",
    meta: { title: "Create Pustaka Hukum", authRequired: true },
    component: () => import("../views/pustakahukum/create")
  },
  {
    path: "/pustakahukum/update/:id",
    name: "pustakahukumUpdate",
    meta: { title: "Update Pustaka Hukum", authRequired: true },
    component: () => import("../views/pustakahukum/update")
  },
  {
    path: "/settings",
    name: "settings",
    meta: { title: "Settings", authRequired: true },
    component: () => import("../views/settings/settings")
  },
  {
    path: "/rencana-pks",
    name: "rencana-pks",
    meta: { title: "Rencana PKS", authRequired: true },
    component: () => import("../views/rencana-pks/rencana-pks")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        const authFake = useAuthFakeStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (authFake.isAuthenticated) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          next();
        }
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/account/logout"),
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        const authFake = useAuthFakeStore();

        authFake.logout();

        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      }
    }
  },
  {
    path: '/:pathMatch(.*)*', // Catch-all route
    name: 'NotFound',
    component: () => import('../views/errors/NotFound.vue'),
    meta: { title: '404 Not Found' }
  },  
  {
    path: "/embed/pustakahukum",
    name: "publicPustakaHukum",
    component: () => import("../views/public/pustakahukum/pustakahukum"),
    meta: { title: "Pustaka Hukum", authRequired: false}
  },
  {
    path: "/embed/pustakahukum/detail/:id",
    name: "publicPustakaHukumDetail",
    component: () => import("../views/public/pustakahukum/detail"),
    meta: { title: "Pustaka Hukum", authRequired: false}
  },
];
