import { createWebHistory, createRouter } from 'vue-router';
import routes from './routes';
import axios from '@/helpers/axios';
import { apiUrl } from '@/config/config';

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

async function checkAuth() {
  try {
      const response = await axios.post(`${apiUrl}api/v1/auth/check`);
      return response.data.authenticated;
  } catch (error) {
      return false;
  }
}

router.beforeEach(async (to, from, next) => {
    const authRequired = to.meta.authRequired;

    if (authRequired) {
        const authenticated = await checkAuth();
        if (!authenticated) {
            return next('/login');
        }
    }

    next();
});

export default router;
